import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import VueI18n from 'vue-i18n';

import 'video.js/dist/video-js.css'

Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
Vue.use(VueI18n);

if(!localStorage.getItem("lang")){
  localStorage.setItem("lang","ja_jp")
}
const i18n = new VueI18n({
  globalInjection: true, 
  locale: localStorage.getItem("lang") || "ja_jp", // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'zh_cn': require('./assets/languages/zh_cn.json'),
    'en_us': require('./assets/languages/en_us.json'),
    'ja_jp': require('./assets/languages/ja_jp.json'),
    'es_spa': require('./assets/languages/es_spa.json'),
    'ms_my': require('./assets/languages/ms_my.json'),
  }
});
new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
